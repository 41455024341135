<template>
    <div id="tendances" class="page-wrapper page-tendances">
        <!-- Details -->
        <section class="article-details site-max-width off-mobile" v-if="pageIsLoaded">
            <div class="article-intro site-max-width-reading">
                <h2 class="small-title suptitle">Page</h2>
                <h1 class="big-title title">{{ content.title }}</h1>
            </div>

            <article class="article-content wysiwyg site-max-width-reading">
                <span v-html="content.contentWysiwyg"></span>
            </article>

            <article class="article-content wysiwyg site-max-width-reading" v-if="pageType === 'plan'">
                <ul>
                    <li>
                        <a href="#" class="" title="Accueil">{{ $t('Accueil') }}</a>
                    </li>
                    <li>
                        <a href="#" class="" title="Expertises">{{ $t('Expertises') }}</a>

                        <ul>
                            <li v-for="item in globals.expertisesEntryShort.data" :key="item.id"><a :href="item.jsonUrl" class="" :title="item.title">{{ item.title }}</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#" class="" title="Industries">{{ $t('Industries') }}</a>

                        <ul>
                            <li v-for="item in globals.secteursEntryShort.data" :key="item.id"><a :href="item.jsonUrl" class="" :title="item.title">{{ item.title }}</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#" class="" title="Réalisations">{{ $t('Réalisations') }}</a>
                    </li>
                    <li>
                        <a href="#" class="" title="À propos">{{ $t('À propos') }}</a>
                    </li>
                    <li>
                        <a href="#" class="" title="Contact">{{ $t('Contact') }}</a>
                    </li>
                    <li>
                        <a href="#" class="" title="Soumission">{{ $t('Soumission') }}</a>
                    </li>
                </ul>
            </article>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'Terms',

    data() {
        return {
            pageTitle: '',
            pageType: '',
        }
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    watch: {
        $route: {
            handler($route) {
                if ($route) {
                    if ($route.name.includes('Politique')) {
                        this.pageTitle = 'Politique de confidentialité'
                        this.pageType = 'politique'
                    }

                    if ($route.name.includes('Plan')) {
                        this.pageTitle = 'Plan du site'
                        this.pageType = 'plan'
                    }

                    if ($route.name.includes('Terms')) {
                        this.pageTitle = "Conditions d'utilisations"
                        this.pageType = 'terms'
                    }
                }
            },
            immediate: true,
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.article-details .wysiwyg {
    padding-top: 0;
    margin-top: 0;

    ul li, ul {
        list-style-type: disc;
        padding: 0;
        padding-left: 10px;

        &:before {
            display: none;
        }

        ul li, ul {
            list-style-type: none;
            padding: 0 20px;
            line-height: 2.5;
            background: none;

            &:before {
                display: none;
            }
        }
    }
}
</style>
